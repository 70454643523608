import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiData";

const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameDetails: builder.query({
      query: ({ uuid }) => ({
        url: `/lgo/game/${uuid}`,
        method: "GET",
        headers: {},
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const { useGetGameDetailsQuery } = gameApi;
export default gameApi;
