import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/store";

ReactDOM.render(
  <Provider store={store}>
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div
        id="innerDiv"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          maxWidth: "480px",
        }}
      >
        <App />
      </div>
    </div>
  </Provider>,
  document.getElementById("root")
);
