import { useState, useLayoutEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      var element = document.getElementById("innerDiv");
      if (element) {
        setSize([element.offsetWidth, element.offsetHeight]);
      } else {
        setSize([window.innerWidth, window.innerHeight]);
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

//https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
